@font-face {
    font-family: 'Open Sans';
    src: url('OpenSans-VariableFont_wdth,wght.ttf');
}

@font-face {
    font-family: 'Montserrat';
    src: url('Montserrat-VariableFont_wght.ttf');
}

@font-face {
    font-family: 'Inter';
    src: url('Inter-VariableFont_slnt,wght.ttf');
}

@font-face {
    font-family: 'DM Sans';
    src: url('DMSans-VariableFont_opsz,wght.ttf');
}
